<script>
import { useStore, mapGetters } from "vuex";
import { watch, reactive, ref, computed } from "vue";
import { useRouter, useRoute } from "vue-router";

import Swal from "@/plugins/swal";

import Paginator from "@/components/paginator/Paginator";
import { useProject } from "@/compositions/models/useProject";

export default {
  name: "ProjectIndex",
  components: { Paginator },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const project = useProject();

    const store = useStore();
    const filters = ref({
      query: "",
      status: { key: "", value: "Status" },
      type: { key: "", value: "Type" }
    });
    const currentPage = ref(route.query.page || 1);
    const state = reactive({
      loading: true,
      types: [],
      statuses: [],
      pagination: {
        offset: 0,
        meta: {
          total: 0
        }
      }
    });

    store.dispatch("projectType/getTypes").then(r => {
      state.types = r.data;
    });

    store.dispatch("projectStatus/getStatuses").then(r => {
      state.statuses = r.data;
    });

    function onPage(event) {
      currentPage.value = event.page + 1;
    }

    function remove(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        confirmButtonText: "Yes, delete it!",
        showCancelButton: true,
        cancelButtonText: "Cancel"
      }).then(r => {
        if (r.isConfirmed) {
          store.dispatch("project/projectDelete", id).then(() => {
            Swal.fire("Deleted!", "Your project has been deleted.", "success");
          });
        }
      });
    }

    watch(
      [currentPage, filters],
      () => {
        store
          .dispatch("project/getProjectList", {
            currentPage: currentPage.value,
            filters: filters.value
          })
          .then(r => {
            state.loading = false;
            state.pagination.meta = r.meta;
            router.push({
              name: "dashboard.client.project.index",
              query: { page: currentPage.value }
            });
          });
      },
      {
        deep: true,
        immediate: true
      }
    );

    return {
      loading: computed(() => state.loading),
      pagination: state.pagination,
      statuses: computed(() => state.statuses),
      types: computed(() => state.types),
      onPage,
      remove,
      status_variants: project.status.variants,
      filters
    };
  },
  computed: {
    ...mapGetters("project", ["projectList"])
  }
};
</script>

<template>
  <div>
    <div class="flex justify-between mt-10">
      <h2 class="text-lg font-medium">
        Projects
      </h2>
      <router-link :to="{ name: 'dashboard.client.project.create' }">
        <base-button>
          <i class="ri-add-box-line mr-1" />Create a new project
        </base-button>
      </router-link>
    </div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div
        class="intro-y col-span-12 flex flex-wrap sm:flex-no-wrap items-center mt-2"
      >
        <div class="w-full sm:w-auto flex mt-3 sm:mt-0 sm:ml-auto md:ml-0">
          <base-select
            v-model="filters.type"
            field="value"
            select-classes="rounded-l-md border h-10 bg-white"
            class="w-48"
            :model-value="filters.type"
            :options="[{ key: '', value: 'Type' }, ...types]"
          />
          <base-select
            v-model="filters.status"
            name="Status"
            select-classes="border h-10 bg-white"
            class="w-48"
            :model-value="filters.status"
            :options="[{ key: '', value: 'Status' }, ...statuses]"
          >
            <template #optionSelected="props">
              <div class="flex items-center">
                <div
                  class="w-4 h-4 rounded-full mr-1"
                  :class="status_variants[props.option.key]"
                />
                {{ props.option.value }}
              </div>
            </template>
            <template #option="props">
              <div class="flex items-center">
                <div
                  class="w-4 h-4 rounded-full mr-1"
                  :class="status_variants[props.option.key]"
                />
                {{ props.option.value }}
              </div>
            </template>
          </base-select>
          <div class="md:w-56 relative text-gray-700 dark:text-gray-300">
            <input
              v-model.lazy="filters.query"
              type="text"
              class="py-2 px-3 rounded-r-md border h-10 appearance-none w-full pr-10 placeholder-theme-13"
              placeholder="Search..."
            >
            <i
              class="w-4 h-5 ri-search-2-line absolute my-auto inset-y-0 mr-3 right-0"
            />
          </div>
        </div>
        <template v-if="projectList.length">
          <div class="hidden md:block ml-auto text-gray-600">
            Showing {{ pagination.meta.from }} to {{ pagination.meta.to }} of
            {{ pagination.meta.total }} entries
          </div>
        </template>
      </div>
      <div
        v-if="
          !loading &&
            !projectList.length &&
            filters.query === '' &&
            filters.status.key === ''
        "
        class="intro-y col-span-12"
      >
        <div class="flex justify-center">
          <div
            class="w-1/2 flex flex-col justify-center content-center text-center"
          >
            <div class="">
              <p class="font-medium text-2xl">
                Looks like you don't have any project!
              </p>
              <p class="text-lg">
                Create a new one.
              </p>
            </div>
          </div>
        </div>
      </div>
      <template v-else>
        <!-- BEGIN: Data List -->
        <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
          <table class="table table-report">
            <thead>
              <tr class="hidden md:table-row">
                <th class="whitespace-no-wrap">
                  Title
                </th>
                <th class="text-center whitespace-no-wrap">
                  Budget
                </th>
                <th class="text-center whitespace-no-wrap">
                  Created date
                </th>
                <th class="text-center whitespace-no-wrap">
                  Start date
                </th>
                <th class="text-center whitespace-no-wrap">
                  End date
                </th>
                <th class="text-center whitespace-no-wrap">
                  Status
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="project in projectList"
                :key="project.id"
                class="intro-x flex md:table-row flex-row md:flex-row flex-wrap md:flex-no-wrap mb-10 md:mb-0"
              >
                <td
                  class="w-full md:w-auto border border-b none md:border-none block md:table-cell relative md:static"
                >
                  <router-link
                    :to="{
                      name: 'dashboard.client.project.show',
                      params: { id: project.id }
                    }"
                    class="font-medium whitespace-no-wrap underline"
                  >
                    {{ project.name }}
                  </router-link>
                  <div class="tags">
                    <span
                      v-for="(skill, key) in project.skills"
                      :key="skill.id"
                      class="bg-blue-100 rounded p-1 text-gray-600 text-xs whitespace-no-wrap"
                      :class="{ 'ml-1': key !== 0 }"
                    >
                      {{ skill.name }}
                    </span>
                  </div>
                </td>
                <td
                  class="w-full md:w-40 flex justify-between text-center border border-b md:border-none block md:table-cell relative md:static"
                >
                  <span
                    class="md:hidden top-0 left-0 bg-indigo-300 px-2 py-1 text-xs font-bold uppercase"
                  >Budget</span>
                  {{ project.budget }}
                </td>
                <td
                  class="w-full md:w-40 flex justify-between border border-b md:border-none block md:table-cell relative md:static"
                >
                  <span
                    class="md:hidden top-0 left-0 bg-indigo-300 px-2 py-1 text-xs font-bold uppercase"
                  >Created</span>
                  {{ project.created_at }}
                </td>
                <td
                  class="w-full md:w-40 flex justify-between border border-b md:border-none block md:table-cell relative md:static"
                >
                  <span
                    class="md:hidden bg-indigo-300 px-2 py-1 text-xs font-bold uppercase"
                  >Start date</span>
                  {{ project.started_at }}
                </td>
                <td
                  class="w-full md:w-40 flex justify-between border border-b md:border-none block lg:table-cell relative lg:static"
                >
                  <span
                    class="md:hidden bg-indigo-300 px-2 py-1 text-xs font-bold uppercase"
                  >End date</span>
                  {{ project.ended_at }}
                </td>
                <td
                  class="w-full md:w-40 flex justify-between border border-b md:border-none block lg:table-cell relative lg:static"
                >
                  <span
                    class="md:hidden bg-indigo-300 px-2 py-1 text-xs font-bold uppercase"
                  >Status</span>
                  <div class="flex items-center justify-center">
                    <div
                      class="w-4 h-4 mr-1 rounded-full"
                      :class="status_variants[project.status.key]"
                    />
                    {{ project.status.value }}
                  </div>
                </td>
                <td
                  class="table-report__action w-full md:w-56 text-right border border-b md:border-none block lg:table-cell relative lg:static"
                >
                  <div class="flex items-center">
                    <router-link
                      :to="{
                        name: 'dashboard.client.project.edit',
                        params: { id: project.id }
                      }"
                      class="flex items-center mr-3"
                    >
                      <i class="ri-edit-box-line mr-1" /> Edit
                    </router-link>
                    <a
                      class="flex items-center text-theme-6 cursor-pointer"
                      @click.prevent="remove(project.id)"
                    >
                      <i class="ri-delete-bin-line mr-1" /> Delete
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- END: Data List -->
        <!-- BEGIN: Pagination -->
        <div
          v-if="pagination.meta.total"
          class="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-no-wrap items-center"
        >
          <Paginator
            template="PrevPageLink PageLinks NextPageLink"
            :rows="10"
            :total-records="pagination.meta.total"
            @page="onPage($event)"
          />
        </div>
        <!-- END: Pagination -->
      </template>
    </div>
  </div>
</template>
